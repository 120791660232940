import { take, takeEvery, put, putResolve, call, race, select, delay, takeLatest, all } from 'redux-saga/effects'

import * as actionTypes from '../actions/actionTypes'
import * as chatActions from '../actions/chat'
import * as settingsActions from '../actions/settings'

import * as chatRepo from '../../store/repository/chat'

import {getItemFromAnArrayById as getById} from '../../utilities/common'

// CONSTANTS
const STATUS_UNREAD = "unread"

// HELPERS
const MESSAGE_SORTER = (a,b) => b.id - a.id
const THREAD_FIRST_SENT_STATUS_TIME_MICROS = t => t.messages.length >= 1 ? t.messages[t.messages.length - 1].sentStatus.timeMicros : null
const THREAD_LAST_SENT_STATUS_TIME_MICROS = t => t.messages.length >= 1 ? t.messages[0].sentStatus.timeMicros : null
//const THREAD_UNREAD_COUNTER = t => t.messages.reduce(a, c => a + c.)

// POLLING
function* poll() {

    try {
        
        const currentState = yield select()

        const { data } = yield call(chatRepo.pollMessages, {
            acceptLanguage: currentState.settings.languageCode,
            credentials: currentState.auth.credentials,
            fromTime: currentState.chat.serverTimeMicros,
        })

        yield putResolve(chatActions.pollSuccess(data))

     } catch (exp) {

        const message = "CHAT: Error getting messages!"
        console.debug(message, exp)

        //TODO: this overload-protection delay should be general!
        yield delay(5000) //NOTE: on case of any error we are going to wait 5 seconds to avoid overload of the backend
        
        yield put(chatActions.pollFail(exp))
    }
}

function* pollWorker(action) {

    console.debug("Message polling did START")

    while (true) {
        try {
            yield call(poll)
        } catch (err) {
            console.debug(err)
        }
    }
}

function* pollManager() {

        const { stop, restart } = yield race({
            poll: pollWorker(null),
            //We have to interrupt the current long polling at the very moment when any of the following cases have happened.
            restart: take(actionTypes.CHAT_POLL_RESTART),
            stop: take([actionTypes.CHAT_POLL_STOP, actionTypes.AUTH_SIGN_OUT_SUCCESS]) // We have to stop the polling on sign out too.
        })
        //console.debug("CHAT: pollManagered 'race' has been finished with results stop: ", stop, " restart: ", restart )

        // handling restart
        if (restart) {
            console.debug("Message polling did RESTART")
            yield call(pollManager)
        }

        // handling stop
        if (stop) {
            console.debug("Message polling did STOP")
            // do nothing
        }
}

function* pollSuccess(action) {
 
    //console.debug("pollSuccess", action)

    const payload = action.payload
    const serverTimeMicros = payload.serverTimeMicros
    const messages = payload.messages

    const currentState = yield select()
    const currentThreads = currentState.chat.threads

    const newThreads = currentThreads
    mergeCurrentMessagesIntoNewThreads(newThreads, messages)
    
    yield putResolve(chatActions.setThreads(newThreads))
    yield putResolve(chatActions.setServerTimeMicros(serverTimeMicros))
}


// THREADS
function* getThreads(action) {
    try {
        
        const currentState = yield select()
        const { data } = yield call(chatRepo.getThreads, currentState.auth.credentials, currentState.settings.languageCode)

        console.debug("Data received: ", data)

        yield putResolve(chatActions.getThreadsSuccess(data))

     } catch (exp) {

        const message = "Error during getThreads!"
        console.debug(message, exp)
      
        yield put(chatActions.getThreadsFail(exp))
    }
}

function* getThreadsByUser(action) {
    try {
        
        const currentState = yield select()
        const filter = action.payload
        
        const { data } = yield call(chatRepo.getThreadsByUser, {
            acceptLanguage: currentState.settings.languageCode,
            credentials: currentState.auth.credentials,
            name: filter
        })

        console.debug("Data received: ", data)

        yield putResolve(chatActions.getThreadsSuccess(data))

     } catch (exp) {

        const message = "Error during getThreadsByUser!"
        console.debug(message, exp)
      
        yield put(chatActions.getThreadsFail(exp))
    }
}

const mergeCurrentMessagesIntoNewThreads = (threads, messages) => {
    
    //console.debug("New (received) threads:", threads, "Current messages:", messages)

    // merge
    messages.forEach(m => {
        const thread = getById(threads, m.threadId)
        if (thread) {
            if (!getById(thread.messages, m.id)) {
                thread.messages.push(m)
            }
            thread.messages = thread.messages.sort(MESSAGE_SORTER)
        } else {
            console.debug(`No thread for threadId ${m.threadId}`)
        }
    })

    // We have to define the first and last time micros of the messages
    threads.forEach(t => {
        t.firstMessageSentTime = THREAD_FIRST_SENT_STATUS_TIME_MICROS(t)
        t.lastMessageSentTime = THREAD_LAST_SENT_STATUS_TIME_MICROS(t)
    })
}

function* getThreadsSuccess(action) {
    
    const currentState = yield select()
    const currentThreads = currentState.chat.threads
    let newThreads = action.payload
    const selectedThreadId = currentState.chat.selectedThreadId

    if (newThreads.length > 0) {
        const currentMessages = []
        currentThreads.forEach( ct => {
            currentMessages.push(...ct.messages)
        })
        mergeCurrentMessagesIntoNewThreads(newThreads, currentMessages)
    }
    
    yield putResolve(chatActions.setThreads(newThreads))

    let newSelectedThreadId = selectedThreadId
    
    if (newThreads.length >= 1) {
        const index = getById(newThreads, selectedThreadId)
        if (!selectedThreadId || !index || index >= newThreads.length) {
            newSelectedThreadId = newThreads[0].id
        }
    } else {
        newSelectedThreadId = null
    }

    yield putResolve(chatActions.setSelectedThreadId(newSelectedThreadId))
    yield put(chatActions.countMessages())
}

// MESSAGES
function* getMessages(action) {
    try {

        const {threadId, lastTimeMicros} = action.payload
        const currentState = yield select()

        const { data } = yield call(chatRepo.getMessages, {
            acceptLanguage: currentState.settings.languageCode,
            credentials: currentState.auth.credentials,
            threadId: threadId,
            lastTimeMicros: lastTimeMicros,
        })

        console.debug("Get messages return data: ", data)

        yield putResolve(chatActions.getMessagesSuccess(data))
    } catch (ex) {
        console.debug("Error getting messages: ",ex)
        yield put(chatActions.getMessagesFail(ex))
    }
}

const mergeNewMessagesIntoCurrentThreads = (threads, messages) => {
    
    threads.forEach(t => {
        const newMessages = messages.filter( m => m.threadId === t.id)
        t.messages.forEach( tm => {
            if (!getById(newMessages, tm.id)) { newMessages.push(tm) }
        })
        t.messages = newMessages.sort(MESSAGE_SORTER)
        t.firstMessageSentTime = THREAD_FIRST_SENT_STATUS_TIME_MICROS(t)
        t.lastMessageSentTime = THREAD_LAST_SENT_STATUS_TIME_MICROS(t)
    })
}

function* getMessagesSuccess(action) {

    const currentState = yield select()
    const threads = currentState.chat.threads
    const messages = action.payload
    
    mergeNewMessagesIntoCurrentThreads(threads, messages)

    yield putResolve(chatActions.setThreads(threads))
    yield put(chatActions.countMessages())
}

function* sendMessage(action) {

    const body = action.payload
    const currentState = yield select()
    const credentials = currentState.auth.credentials
    const threadId = currentState.chat.selectedThreadId

    const sendMessageParameters = {
        acceptLanguage: currentState.settings.languageCode,
        credentials: credentials,
        threadId: threadId,
        body: body
    }

    const getMessagesPayload = {
        threadId: threadId,
        
        //XXX: just for sure, we jump forward 3 seconds to the future, to be sure to get the new messages
        //XXX: consider to change to a more robust solution
        lastTimeMicros: (new Date().getTime() + 3000) * 1000
    }

    //1. stop the long-polling
    yield putResolve(chatActions.pollStop())
    
    //2. send the message
    yield call(chatRepo.sendMessage, sendMessageParameters)

    //3. do the 'cheap' update
    yield putResolve(chatActions.getMessages(getMessagesPayload))

    //4. restart the long-polling
    yield putResolve(chatActions.pollStart())
    
    //5. send the success mnessage
    yield putResolve(chatActions.sendMessageSuccess())

    //6. count messages
    yield put(chatActions.countMessages())
}

function* sendMessageSuccess(action) {
    //do nothing
}

function* authSuccess(action) {
    //do nothing
}

function* countMessages(action) {

    const currentState = yield select()
    const threads = currentState.chat.threads
    
    const counters = {
        totalUnreadMessageCount: 0,
        threads: {}
    }

    threads.forEach( t => {
        let threadCounters = counters.threads[`${t.id}`]
        threadCounters = {
            unreadMessageCount: 0
        }
        t.messages.forEach( m => {
            if (m.readStatus.name === STATUS_UNREAD) {
                counters.totalUnreadMessageCount++
                threadCounters.unreadMessagesCount++
            }
        })
    })

    yield putResolve(chatActions.setCounters(counters))
}

function* changeFilter(action) {

    yield putResolve(chatActions.setFilteringInProgress(true))

    const filter = action.payload

    if (filter && filter.length > 3) {
        yield putResolve(chatActions.getThreadsByUser(filter))
        console.debug(`Filter in use: `, filter)
    } else {
        yield putResolve(chatActions.getThreads(filter))
        console.debug(`Filter NOT in use: `, filter)
    }

    yield all([
        take(actionTypes.CHAT_GET_THREADS_SUCCESS),
        take(actionTypes.CHAT_SET_THREADS),
        take(actionTypes.CHAT_SET_SELECTED_THREAD_ID),
    ]);
    console.debug('After all(): All necessary sagas are done.', filter)

    yield putResolve(chatActions.setFilter(filter))

    yield putResolve(chatActions.setFilteringInProgress(false))
}

function* threadIdSelectedByUser(action) {
    const { payload } = action
    if (process.env.REACT_APP_HIDE_FILTER_PANE_ON_CHAT_THREAD_ID_SELECTED_BY_USER) {
        yield putResolve(settingsActions.setFilterPaneVisible(false))
    }
    yield putResolve(chatActions.setSelectedThreadId(payload))
}

function* navigationBottomMenuItemClick(action) {
    const { payload } = action

    console.debug("XXX: Chat navigation bottom menu item handler has been triggered by:", payload)

    //use a const like BOTTOM_MENU_ITEM_CHAT
    if (payload.id === 3) {
        console.debug("XXX: The chat bottom menu item has been clicked, getting the chat threads...")
        yield putResolve(chatActions.getThreads())
    }
}

export function* saga() {
    
    yield takeEvery(actionTypes.AUTH_SUCCESS, authSuccess)

    yield takeEvery(actionTypes.NAVIGATION_BOTTOM_MENU_ITEM_CLICK, navigationBottomMenuItemClick)

    yield takeEvery([
        actionTypes.CHAT_POLL_START,
        actionTypes.AUTH_SUCCESS,
     ], pollManager)

    yield takeEvery(actionTypes.CHAT_THREAD_ID_SELECTED_BY_USER, threadIdSelectedByUser)

    yield takeEvery(actionTypes.CHAT_POLL_SUCCESS, pollSuccess)    

    yield takeEvery(actionTypes.CHAT_GET_MESSAGES, getMessages)
    yield takeEvery(actionTypes.CHAT_GET_MESSAGES_SUCCESS, getMessagesSuccess)

    yield takeEvery(actionTypes.CHAT_GET_THREADS, getThreads)
    yield takeEvery(actionTypes.CHAT_GET_THREADS_BY_USER, getThreadsByUser)
    yield takeEvery(actionTypes.CHAT_GET_THREADS_SUCCESS, getThreadsSuccess)

    yield takeLatest(actionTypes.CHAT_CHANGE_FILTER, changeFilter)

    yield takeEvery(actionTypes.CHAT_SEND_MESSAGE, sendMessage)
    yield takeEvery(actionTypes.CHAT_SEND_MESSAGE_SUCCESS, sendMessageSuccess)

    yield takeEvery(actionTypes.CHAT_COUNT_MESSAGES, countMessages)
}